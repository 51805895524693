<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import { useProvider } from '/~/composables/provider'

const { page, haveRightWidget } = useEditablePage()
const sideRight = ref()
const sideLeft = ref()
const { isBillPaymentsV15Template } = useProvider()
let resizeObserver: ResizeObserver

onMounted(() => {
  resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      const { height } = entry.contentRect

      if (sideRight.value?.style?.maxHeight) {
        sideRight.value.style.maxHeight = `${height}px`
      }
    }
  })

  if (sideLeft.value instanceof Element) {
    resizeObserver.observe(sideLeft.value)
  }
})

onBeforeUnmount(() => {
  resizeObserver?.disconnect()
})
</script>

<template>
  <div
    class="flex min-h-full flex-col gap-5 md:flex-row lg:gap-8"
    :class="{
      'pr-6': !isBillPaymentsV15Template,
    }"
  >
    <div
      ref="sideLeft"
      class="w-full grow bg-eonx-neutral-50 md:w-2/3 md:rounded-b-none"
      :class="{
        'rounded-b-3xl rounded-t-3xl': !isBillPaymentsV15Template,
      }"
    >
      <div class="mx-auto w-full space-y-5">
        <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
      </div>
    </div>

    <div
      v-if="haveRightWidget"
      ref="sideRight"
      class="w-full overflow-auto md:w-[376px]"
    >
      <widgets-builder
        :widgets="page.widgets"
        side="right"
        class="h-full w-full !space-y-5"
      />
    </div>
  </div>
</template>
