<script setup lang="ts">
import { useEditablePage } from '/~/composables/cms/use-editable-page'

const { page } = useEditablePage()
</script>

<template>
  <div class="overflow-x-hidden p-5">
    <widgets-builder :widgets="page.widgets" />
    <widgets-builder :widgets="page.widgets" side="right" />
  </div>
</template>
